/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;500&display=swap');
*{
  font-family: 'Barlow', sans-serif;
}


h4{
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
}
#themecolors a {
    cursor: pointer;
}

.invalid-feedback{
    display: block !important;
}

.logo-kamgus{
    width: 100%;
    margin-bottom: 30px;
}

.login-box {
    width: 390px;
    margin: 0 auto;
    margin-top: -32px !important;
}

.logo-white-register{
    width: 40%;
}

.mg-logo{
    margin: 80px 0px;
}

.title-register{
    color: white;
    font-size: 34px;
    font-weight: 600;
    margin: 30px 0px;
}

.p-register{
    color: white;
}

.card-kg{
    border-radius: 15px !important;
    border: 1px solid #dcdcdc !important;
    box-shadow: 0px 5px 2px #eee !important;
}

h4{
    color: #1d124f;
    font-size: 22px;
    font-weight: 500;
}

.input-format{
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 2px #eee;
    padding: 10px;
    margin: 25px 0px 10px 0px;
}

.icons-wallet{
    color:rgb(18, 6, 57);
    font-size: 26px;
    margin-top: 38px;
    cursor: pointer;
}



.card-services{
    width: 25%;
    position: absolute;
    top: 70px;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    padding: 20px 20px 0 20px;
    box-shadow: none;
    left: 5%;
}
.card-services.active{
    box-shadow: 2px 3px 2px #bfbfbf !important;
} 

.card-title-k{
    background-color: #e6e6e6;
    color: #1d124f;
    text-align: center;
    font-weight: 600;
    position: relative;
    margin-top: -20px;
    margin-left: -20px;
    width: 114%;
    padding: 10px;
    font-size: 22px;
}

.input-group-addon {
    color: #495057;
    text-align: center;
    background-color: #ffffff;
   
}

.btn-warning.disabled, .btn-warning:disabled {
    background-color: #67757c;
    border-color: #67757c;
    box-shadow: none;
}

.img-width-100{
    width: 120px;
}

.img-categories{
    width: 100%;
}

.mg{
    margin: 5px 0px;
    padding: 20px 0px;
}

.auto-align {
    margin: auto;
}

.pricing-orange{
    color: #ec6334;
    font-size: 20px;
}

.click{
    cursor: pointer;
}

.vehicle-selected{
    background-color: #fbeee9;
    border-left: 10px solid #eb6133;
}

.btn-primary, .btn-primary.disabled {
    background: #1d124f;
    border: 1px solid #1d124f;
}

.color-orange{
    color: #ec6334;
    font-size: 22px;
}
.color-white{
    color: #fff;
    font-size: 22px;
}

.mtop-botton{
    margin: 20px 0px;
}

.color-blue{
    color: #1d124f;
}

.top-bottoms{
    margin-bottom: 21px;
}
.btn-secondary {
    color: #1d124f !important;
    font-weight: 500;
}
.btn-secondary:hover {
    background: #1d124f !important;
    font-weight: 500;
    color: #fff !important;
}
.kart{
    font-size:30px;
    margin-right: -18px;
}
.cart-items{
    background: #eee;
    text-align: center;
    /* padding: 23%; */
    border-radius: 50%;
    margin-top: -9px;
    color: #eb6333;
    border: 1px solid #eb6333;
    font-weight: 500;
    width: 50px;
    height: 50px;
    padding: 12px;
}

.cats {
    display:inline-block; 
    width: 19%;
}

.modal-header {
    background: #eb6333 !important;
    color: #fff !important;
}
.modal-title{
    color: #fff !important;
}

.invalid{
    color: red;
}
.time-arrived{
    border: 1px solid #eb6333;
    padding: 5px 10px;
    color: #1d124f !important;
    font-weight: 600;
}
.border-bottom-orange{
    border-bottom: 20px solid #eb6333 !important;
}

.services-order{
    background: #eb6333;
    color: #fff;
    padding: 9px 10px;
    margin-top: -10px;
    margin-bottom: -10px;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
}
.labels{
    border-radius: 10px;
    padding: 5px 10px;
}
.status-terminado{
    background-color: #73b955;
    color: #fff;
}
.status-anulado{
    background-color: #fc9732;
    color: #fff;
}
.status-cancelado{
    background-color: #e83e33;
    color: #fff;
}
.status-repetir{
    background-color: #baea36;
    color: #fff;
}
.status-pendiente{
    background-color: #f8c539;
    color: #fff;
}
.clear-button{
    background: none;
    border: none;
    color: #1d124f;
    margin-top: 30px;
    box-shadow: none;

}
.mtop-negative{
    margin-top: 2px;
}

.dash-pricing{
    font-size: 30px;
    font-weight: 500;
    margin-bottom: -11px;
    color: #1d124f;
}
.table-spacing-h{
    margin: 2px 0px;
    padding: 11px 0px;
}
.hover-efect:hover{
    background: #eee;
    cursor: pointer;
}
.cont-login{
    
    height: 100%;
}
.login-company{
    background: rgb(43, 36, 223);
   
}
.login-user{
    background: #eb6434;
    
}
.cont-white{
    background: #fff;
}
.pd-forms-login{
    padding: 12%;
}
.spacer-100{
    height:100px;
}
.img-80{
    width: 80%;
}
.p-login{
    line-height: 100%;
    font-weight: 300;
}
agm-map {
    height: 100%;
    width: 101%;
    left: 0;
    position: fixed;
    top: 70px;
}

.pd-forms-register {
    padding: 3% 8%;
}

/* @media (max-width: 600px) {
    .facet_sidebar {
      display: none;
    }
  } */

  @media (max-width: 600px) {
    .no-display-movil {
      display: none;
    }
  }

.btn-outline-secondary {
    background-color: #ffffff;
    border: none;
    box-shadow: none;
    margin-top:10px;
}

.input-register{
    background: #f7f6f6 !important;
    border-radius: 15px !important;
    padding: 13px !important;
    margin: 4px 0px;

}

.btn-facebook{
    border-radius: 30px;
}


@media (max-width: 100px) {
    .facet_sidebar {
      display: none;
    }
  }
.card-pricing{
    box-shadow: 1px 1px 5px rgb(199, 196, 196);
    border-radius: 20px;
    padding: 20px;
    margin: 0px 10%;
}
.pd-thank-you{
    padding: 0px 30%;
}
.box-payment{
    background: #170b39;
    padding: 24px;
    border-radius: 16px;
}

/*Input tipo checkbox*/

input[type="checkbox"]{
    appearance: none !important;
    -webkit-appearance: none !important;
    height: 80px;
    width: 80px;
    background: red;
  }

  
  